import { CurrentCustomerUserQueryApi, CurrentCustomerUserQueryDocumentApi } from 'graphql/generated';
import { Client } from 'urql';

const checkIsLoggedOnSSR = (client: Client | null): boolean => {
    const customerQueryResult = client?.readQuery<CurrentCustomerUserQueryApi>(CurrentCustomerUserQueryDocumentApi, {});
    const isLogged =
        customerQueryResult?.data?.currentCustomerUser !== undefined &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        customerQueryResult?.data?.currentCustomerUser !== null;

    return isLogged;
};

export default checkIsLoggedOnSSR;
