import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Heading from 'components/Basic/Heading/Heading';
import Image from 'components/Basic/Image/Image';
import CommonLayout from 'components/Layout/CommonLayout';
import Webline from 'components/Layout/Webline/Webline';
import { useDomainConfig } from 'hooks/useDomainConfig';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

export const Error404 = () => {
    const { t } = useTranslation();
    const { url: homepageUrl } = useDomainConfig();

    return (
        <CommonLayout title={t('Something wrong happened... Page not found "404"')} withoutMoveUpActionButton>
            <Webline>
                <div className="flex flex-col items-center pb-14">
                    <Heading type="h1">{t('Page not found')}</Heading>
                    <Image height={200} width={500} alt={t('404')} src="/public/frontend/images/404.jpg" />
                    <Heading type="h6">{t('We are sorry, but the requested page does not exists - error 404')}</Heading>
                    <div className="leading-6 text-grayVeryDark">
                        {t(
                            "You're looking for a page that doesn't exist. Maybe it was there before, maybe you used a link with a wrong address.",
                        )}
                    </div>
                    <div className="leading-6 text-grayVeryDark">
                        <Trans
                            i18nKey="Error404SearchOrHomepage"
                            components={{
                                link: (
                                    <ExtendedNextLink type="static" href="/">
                                        {homepageUrl}
                                    </ExtendedNextLink>
                                ),
                            }}
                        />
                    </div>
                </div>
            </Webline>
        </CommonLayout>
    );
};
