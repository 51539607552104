import checkIsLoggedOnSSR from './checkIsLoggedOnSSR';
import { getCartUuidCookieOnSsr } from './cookies/cartUuidCookieHelper';
import { getDefaultStoreUuidCookieOnSsr } from './cookies/defaultStoreCookieHelper';
import { getSocialLoginDataCookie, getUserConsentCookie } from './cookies/getUserConsentCookie';
import { createClient } from './createClient';
import { logException } from './errors/logException';
import { getIsRedirectedFromSsr } from './isServer';
import { getServerSidePropsWrapper } from './serverSide/getServerSidePropsWrapper';
import { Variables } from '@urql/exchange-graphcache';
import { NO_OF_HEADER_LINKS } from 'components/Layout/Header/HeaderTop/HeaderLinks/HeaderLinks';
import { DocumentNode } from 'graphql';
import {
    AdvertsQueryDocumentApi,
    AdvertsQueryVariablesApi,
    CurrentCustomerUserQueryDocumentApi,
    FooterSettingQueryDocumentApi,
    FooterSettingQueryVariablesApi,
    GdprActionTypeEnumApi,
    GdprConsentsQueryDocumentApi,
    GdprConsentsQueryVariablesApi,
    NotificationBarsDocumentApi,
    NotificationBarsVariablesApi,
    SettingsQueryDocumentApi,
    SettingsQueryVariablesApi,
    TopMenuArticlesQueryDocumentApi,
    TopMenuArticlesQueryVariablesApi,
} from 'graphql/generated';
import { getLastVisitedProductsCatNumbersCookie } from 'helpers/lastVIsited/lastVisitedProductsHelper';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { Translate } from 'next-translate';
import loadNamespaces from 'next-translate/loadNamespaces';
import { RedisClientType, RedisFunctions, RedisModules, RedisScripts } from 'redis';
import { SocialLoginDataType } from 'types/cookie';
import { UserConsentFormType } from 'types/form';
import { Client, SSRData, SSRExchange, ssrExchange } from 'urql';
import { DomainConfigType } from 'utils/Domain/Domain';
import {
    getInternationalizedStaticUrls,
    getServerSideInternationalizedStaticUrl,
} from 'utils/getInternationalizedStaticUrls';

export type ServerSidePropsType = {
    urqlState: SSRData;
    domainConfig: DomainConfigType;
    userConsentCookie: UserConsentFormType | null;
    socialLoginData: SocialLoginDataType | null;
    defaultStoreUuid: string | null;
    cartUuid: string | null;
    isMaintenance: boolean;
    lastVisitedProductsCatNumbers: string[] | null;
};

export type QueriesArray<VariablesType> = { query: string | DocumentNode; variables?: VariablesType }[];

type InitServerSidePropsParameters<VariablesType> = {
    domainConfig: DomainConfigType;
    context: GetServerSidePropsContext;
    authenticationRequired?: boolean;
    prefetchedQueries?: QueriesArray<VariablesType>;
    additionalProps?: Record<string, any>;
} & (
    | {
          client: Client;
          redisClient?: never;
          ssrExchange: SSRExchange;
          t?: never;
      }
    | {
          client?: never;
          redisClient: RedisClientType<RedisModules, RedisFunctions, RedisScripts>;
          ssrExchange?: SSRExchange;
          t: Translate;
      }
);

export const initServerSideProps = async <VariablesType extends Variables>({
    domainConfig,
    context,
    redisClient,
    t,
    authenticationRequired = false,
    prefetchedQueries: additionalPrefetchQueries = [],
    client,
    ssrExchange: ssrExchangeOverride,
    additionalProps = {},
}: InitServerSidePropsParameters<VariablesType>): Promise<GetServerSidePropsResult<ServerSidePropsType>> => {
    try {
        const currentSsrCache = ssrExchangeOverride ?? ssrExchange({ isClient: false });
        const currentClient =
            client ??
            createClient({
                ssrExchange: currentSsrCache,
                redisClient,
                context,
                t,
                publicGraphqlEndpoint: domainConfig.publicGraphqlEndpoint,
            });

        const queriesNotToBeFetchedDuringClientSideNavigation = [
            { query: NotificationBarsDocumentApi },
            { query: AdvertsQueryDocumentApi },
            { query: FooterSettingQueryDocumentApi },
            { query: SettingsQueryDocumentApi },
            {
                query: TopMenuArticlesQueryDocumentApi,
                variables: { noOfArticles: NO_OF_HEADER_LINKS },
            },
            {
                query: GdprConsentsQueryDocumentApi,
                variables: { action: GdprActionTypeEnumApi.EmailApi, orderType: null },
            },
        ];

        const isRedirectedFromSsr = getIsRedirectedFromSsr(context.req.headers);
        const prefetchQueries: QueriesArray<
            | NotificationBarsVariablesApi
            | AdvertsQueryVariablesApi
            | FooterSettingQueryVariablesApi
            | SettingsQueryVariablesApi
            | TopMenuArticlesQueryVariablesApi
            | GdprConsentsQueryVariablesApi
            | Variables
        > = [
            { query: CurrentCustomerUserQueryDocumentApi },
            ...(isRedirectedFromSsr ? queriesNotToBeFetchedDuringClientSideNavigation : []),
            ...additionalPrefetchQueries,
        ];

        const resolvedQueries = await Promise.all(
            prefetchQueries.map((queryObject) =>
                currentClient.query(queryObject.query, queryObject.variables).toPromise(),
            ),
        );

        const isAllQueriesHaveInternalServerError = resolvedQueries.every(
            (query) => query.error?.graphQLErrors[0]?.extensions.code === 500,
        );

        if (isAllQueriesHaveInternalServerError) {
            throw new Error('Internal Server Error');
        }

        const { trimmedUrlWithoutQueryParams } = getServerSideInternationalizedStaticUrl(context, domainConfig.url);

        if (domainConfig.type === 'b2b') {
            const isLogged = checkIsLoggedOnSSR(currentClient);

            const allowedUrlsOnB2B = getInternationalizedStaticUrls(
                ['/b2b-login', '/b2b-reset-password', '/b2b-recover-password'],
                domainConfig.url,
            );

            if (!allowedUrlsOnB2B.includes(trimmedUrlWithoutQueryParams) && !isLogged) {
                return {
                    redirect: {
                        statusCode: 302,
                        destination: getInternationalizedStaticUrls(['/b2b-login'], domainConfig.url)[0],
                    },
                };
            }
        } else if (authenticationRequired) {
            const isLogged = checkIsLoggedOnSSR(currentClient);

            if (!isLogged) {
                return {
                    redirect: {
                        statusCode: 302,
                        destination: getInternationalizedStaticUrls(['/login'], domainConfig.url)[0],
                    },
                };
            }
        }

        const isMaintenance = resolvedQueries.some((query) => query.error?.response?.status === 503);
        if (isMaintenance) {
            context.res.statusCode = 503;
        }

        return {
            props: {
                ...(await loadNamespaces({
                    locale: domainConfig.defaultLocale,
                    pathname: trimmedUrlWithoutQueryParams,
                })),
                // JSON.parse(JSON.stringify()) fix of https://github.com/vercel/next.js/issues/11993
                urqlState: JSON.parse(JSON.stringify(currentSsrCache.extractData())),
                isMaintenance,
                domainConfig,
                userConsentCookie: getUserConsentCookie(context),
                socialLoginData: getSocialLoginDataCookie(context),
                defaultStoreUuid: getDefaultStoreUuidCookieOnSsr(context),
                cartUuid: getCartUuidCookieOnSsr(context),
                lastVisitedProductsCatNumbers: getLastVisitedProductsCatNumbersCookie(context),
                ...additionalProps,
            },
        };
    } catch (e) {
        logException(e);
        throw e;
    }
};

export const getServerSidePropsCookiesAllowed = getServerSidePropsWrapper(
    ({ domainConfig, redisClient, t }) =>
        async (context) => {
            const userConsentCookieAllowed: UserConsentFormType = {
                statistics: true,
                marketing: true,
                preferences: true,
            };

            return initServerSideProps({
                context,
                domainConfig,
                redisClient,
                t,
            }).then((result) => {
                if ('props' in result && 'userConsentCookie' in result.props) {
                    result.props.userConsentCookie = userConsentCookieAllowed;
                }

                return result;
            });
        },
);
